import { CameraPairingForm } from 'components/camera/list/pairing/components/cameraPairingForm/components'
import { CameraSelector } from 'components/camera/list/pairing/components/cameraSelector/components/CameraSelector'
import { SoftwareInstallation } from 'components/camera/list/pairing/components/deploy/components/SoftwareInstallation'
import { ReactNode } from 'react'
import { Step } from 'types/global'

export const enum PAIRING_STEPS {
  CAMERA_SELECTION = 0,
  PAIRING = 1,
  DEPLOY = 2,
  DEPLOY_STATUS = 3,
  LICENSING = 4,
}

export interface PairingStep extends Step {
  title: string
  footer?: ReactNode
}

export const pairingSteps: PairingStep[] = [
  {
    index: PAIRING_STEPS.CAMERA_SELECTION,
    title: 'Which camera are you going to pair?',
    component: <CameraSelector />,
    isCompleted: () => true,
  },
  {
    index: PAIRING_STEPS.PAIRING,
    title: 'Camera pairing',
    component: <CameraPairingForm />,
    footer: <CameraPairingForm.Footer />,
    isCompleted: (pairingData) => {
      return false
    },
  },
  {
    index: PAIRING_STEPS.DEPLOY,
    title: 'Software Installation',
    component: <SoftwareInstallation />,
    isCompleted: () => true,
  },
]
