import { Avatar, Typography } from 'antd'
import { AuthenticationEndpoints } from 'api/axios/axiosAPIGroups'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { DEFAULT_USER_IMAGE } from 'constants/sports'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import paths from '../../../constants/paths'
import { useAuth } from '../../../hooks/useAuth'
import styles from './Menu.module.css'
import logoImg from '/public/logo.png'

const { Text } = Typography

export const Menu = () => {
  const { t } = useTranslation('common')
  const [openMenu, setOpenMenu] = useState(false)
  const router = useRouter()
  const { user } = !router.pathname.includes('forbidden') && useAuth()
  const { requestHandler } = useHTTPRequestHandler()

  const logout = () => {
    requestHandler({
      requestPromise: AuthenticationEndpoints.logoutAuthLogoutPost(),
      onOkCallback: () => {
        localStorage.clear()
        sessionStorage.clear()
        router.replace(`${window.location.origin}${paths.auth.callback}`)
      },
    })
  }
  return (
    <div className={styles.menu}>
      <Link href="/">
        <Image src={logoImg} alt="Logo AutomaticTV" />
      </Link>

      {user && (
        <>
          <div className={styles.log}>
            {/* // TODO: replace with avatar img */}
            <Avatar size={32} src={DEFAULT_USER_IMAGE}></Avatar>
            <Icon
              data_testid="menu"
              component={IconNames.CHEVRON_DOWN}
              size={16}
              onClick={() => {
                setOpenMenu(!openMenu)
              }}
              className={openMenu ? styles.arrow : ''}
            ></Icon>
          </div>
          <div className={openMenu ? styles.menuBox : styles.closed}>
            <Text className="pb-05 semibold">{`${user.first_name} ${user.last_name}`}</Text>
            <Text className={styles.mail}>{user.username}</Text>
            <div className={styles.logout} onClick={logout}>
              <Icon component={IconNames.LOGOUT}></Icon>
              <Text data-testid="log-out">{t('logout')}</Text>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
