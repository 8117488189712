import { DeployStatusEnum, DeviceDeploymentDTO } from 'api/api_code'
import { DeviceDetailsEndpoints } from 'api/axios/axiosAPIGroups'
import { pairingSteps, PAIRING_STEPS } from 'core/pairing/pairing'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { useLoading } from 'hooks/useLoading'
import { usePairingContext } from 'hooks/usePairingContext'
import useTranslation from 'next-translate/useTranslation'
import { useRef } from 'react'

export function usePairing() {
  const { t } = useTranslation('cameras')
  const { t: tCommon } = useTranslation('common')
  const { setLoading, setLoadingInfo } = useLoading()
  const { setPairingModalState, setCurrentStep, pairedDeviceUUID, setSoftwareInstallationState } =
    usePairingContext()
  const pollingInterval = useRef(null)
  const { requestHandler } = useHTTPRequestHandler()

  const checkDeploymentState = (deploymentUUID: string, initTime: number) => {
    requestHandler({
      requestPromise: DeviceDetailsEndpoints.getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(
        pairedDeviceUUID.current,
        deploymentUUID
      ),
      onOkCallback: (deviceDeployment) => handleDeploymentState(deviceDeployment, initTime),
      autoLoading: false,
    })
  }

  const handleDeploymentState = (deploymentStatus: DeviceDeploymentDTO, initTime: number) => {
    if (
      deploymentStatus.status === DeployStatusEnum.Succeed ||
      deploymentStatus.status === DeployStatusEnum.Failed
    ) {
      clearInterval(pollingInterval.current)
      setCurrentStep({
        index: 3,
        component: null,
        isCompleted(data) {
          return true
        },
        title: null,
      })
      setSoftwareInstallationState(deploymentStatus.status)
      setLoading(false)
      return
    }

    // Elapsed time
    const currentTime = performance.now()
    const elapsedTime = currentTime - initTime

    let waitMessage = t('softwareInstallation.waitMessage.lessThanThirtySecs')
    let width = 300
    if (elapsedTime < 30 * 1000) {
      waitMessage = t('softwareInstallation.waitMessage.lessThanThirtySecs')
      width = 300
    } else if (elapsedTime >= 30 * 1000 && elapsedTime < 60 * 1000) {
      waitMessage = t('softwareInstallation.waitMessage.lessThanSixtySecs')
      width = 400
    } else if (elapsedTime >= 60 * 1000 && elapsedTime < 3 * 60 * 1000) {
      waitMessage = t('softwareInstallation.waitMessage.lessThanThreeMins')
      width = 440
    } else if (elapsedTime >= 3 * 60 * 1000 && elapsedTime < 5 * 60 * 1000) {
      waitMessage = t('softwareInstallation.waitMessage.lessThanFiveMins')
      width = 500
    } else if (elapsedTime >= 5 * 60 * 1000 && elapsedTime < 10 * 60 * 1000) {
      waitMessage = t('softwareInstallation.waitMessage.lessThanTenMins')
      width = 380
    } else {
      waitMessage = t('softwareInstallation.waitMessage.overTime')
      width = 620
    }

    setLoadingInfo({ header: tCommon('installing'), extended: waitMessage, width })
  }

  const deploymentStatusHandler = (deployment: DeviceDeploymentDTO) => {
    setPairingModalState(false)
    setLoading(true)
    setLoadingInfo({
      header: tCommon('installing'),
      extended: t('softwareInstallation.waitMessage.lessThanThirtySecs'),
      width: 300,
    })
    const initTime = performance.now()
    pollingInterval.current = setInterval(
      () => checkDeploymentState(deployment.uuid, initTime),
      30 * 1000
    )
  }

  // Close popup and trigger software installation deployment
  const deploySoftwareInstallation = () => {
    setCurrentStep(pairingSteps[PAIRING_STEPS.DEPLOY])
    requestHandler({
      requestPromise: DeviceDetailsEndpoints.deployAutomatictvDevicesDeviceUuidDeployPost(
        pairedDeviceUUID.current
      ),
      onOkCallback: (deployment: DeviceDeploymentDTO) => {
        deploymentStatusHandler(deployment)
      },
      autoLoading: false,
    })
  }

  return {
    deploySoftwareInstallation,
  }
}
