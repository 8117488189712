import { Button } from 'antd'
import { DefaultResponseDTO, DeviceRegisterResponseDTO } from 'api/api_code'
import { OrganizationsEndpoints } from 'api/axios/axiosAPIGroups'
import { AxiosError } from 'axios'
import { pairingSteps } from 'core/pairing/pairing'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { usePairingContext } from 'hooks/usePairingContext'
import useTranslation from 'next-translate/useTranslation'

const CameraPairingFormFooter = () => {
  const {
    setCurrentStep,
    cameraType,
    pairingData,
    pairedDeviceUUID,
    serialStatus,
    setSerialStatus,
  } = usePairingContext()
  const { t } = useTranslation('cameras')
  const { requestHandler } = useHTTPRequestHandler()
  const disabledState = () => {
    if (cameraType === 'ONE') {
      // TODO: Add Server serial number restriction
      return (
        serialStatus.length > 0 ||
        !pairingData.name ||
        !pairingData.organization_uuid ||
        !pairingData.serial_number_producer
      )
    }
    return (
      serialStatus.length > 0 ||
      !pairingData.name ||
      !pairingData.organization_uuid ||
      !pairingData.serial_number_producer
    )
  }

  const handleSaveAndSoftwareInstallation = () => {
    const sentPairingData = { ...pairingData }
    delete sentPairingData.organization_uuid
    requestHandler({
      requestPromise: OrganizationsEndpoints.pairDeviceOrganizationsOrganizationUuidPairDevicePost(
        pairingData.organization_uuid,
        sentPairingData
      ),
      onOkCallback: (device: DeviceRegisterResponseDTO) => {
        pairedDeviceUUID.current = device.uuid
        setCurrentStep((prevStep) => pairingSteps[prevStep.index + 1])
      },
      onKo: {
        onKoCallback: (error: AxiosError) => {
          const nestedInternalMessage = (error.response.data as DefaultResponseDTO).message
          if (nestedInternalMessage.includes('134')) {
            return setSerialStatus([
              { name: 'serial', errors: [t('pairNewCameraModal.errors.deviceAlreadyPaired')] },
            ])
          }
          if (nestedInternalMessage.includes('102')) {
            return setSerialStatus([
              { name: 'serial', errors: [t('pairNewCameraModal.errors.nonExistentSerialNumber')] },
            ])
          }
        },
        overrideCallback: true,
      },
    })
  }

  return (
    <Button
      aria-label="save and start software installation"
      type="primary"
      onClick={handleSaveAndSoftwareInstallation}
      disabled={disabledState()}
    >
      {t('softwareInstallation.start')}
    </Button>
  )
}

export default CameraPairingFormFooter
