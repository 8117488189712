import { SessionDTO } from 'api/api_code'
import { AxiosManager } from 'api/axios/axios'
import { DeviceConfigurationEndpoints } from 'api/axios/axiosAPIGroups'
import { X_WIZARD_SESSION_TOKEN } from 'api/axios/constants'
import { setToSessionStorage } from 'helpers/storageUtils'
import { useHTTPRequestHandler } from './useHTTPRequestHandler'
import { useRouter } from 'next/router'

export const useSession = () => {
  const { requestHandler, cancelForceSession, forceStartSession } = useHTTPRequestHandler()
  const router = useRouter()

  const startWizardSession = async (
    device_uuid: string,
    onSessionStartedCallback?: CallableFunction
  ) => {
    return new Promise((resolve) =>
      requestHandler({
        requestPromise:
          DeviceConfigurationEndpoints.startWizardSessionDevicesDeviceUuidConfigurationSessionPost(
            device_uuid as string
          ),
        onOkCallback: (session: SessionDTO) => {
          setToSessionStorage('session_token', session['token'])
          AxiosManager.getInstance().addCommonHeader(X_WIZARD_SESSION_TOKEN, session['token'])
          resolve(onSessionStartedCallback ? onSessionStartedCallback() : true)
        },
        onKo: {
          onKoCallback: onSessionStartedCallback,
          overrideCallback: false,
        },
      })
    )
  }

  const closeWizardSession = async (
    device_uuid: string,
    onClosedCallback?: CallableFunction,
    onCloseErrorCallback?: CallableFunction
  ) => {
    requestHandler({
      requestPromise:
        DeviceConfigurationEndpoints.stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(
          device_uuid as string
        ),
      onOkCallback: () => {
        detachWizardSession()
        onClosedCallback && onClosedCallback()
      },
      onKo: {
        onKoCallback: () => {
          detachWizardSession()
          onCloseErrorCallback && onCloseErrorCallback()
        },
      },
    })
  }

  function detachWizardSession() {
    sessionStorage.removeItem('session_token')
    delete AxiosManager.getInstance().getAxiosInstance().defaults.headers.common[
      X_WIZARD_SESSION_TOKEN
    ]
  }

  return {
    startWizardSession,
    closeWizardSession,
    cancelForceSession,
    forceStartSession,
  }
}
